<template>
  <div>
    <v-dialog v-model="registerDialog" width="1000" :fullscreen="fullScreen">
      <v-card color="#0d0d0d">
        <v-toolbar flat style="padding-right: 10px !important">
          <v-toolbar-title>Cadastro de Produto</v-toolbar-title>
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="clearItem()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <br />
            <br />
            <v-row>
              <v-col cols="8" class="mt-n8">
                <span>Nome</span>
                <v-text-field
                  solo
                  v-model="product.name"
                  placeholder="Nome"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="mt-n8">
                <span>Estoque</span>
                <v-text-field
                  solo
                  v-model="product.stock"
                  placeholder="Estoque"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="mt-n8">
                <span>Categoria</span>
                <v-autocomplete
                  solo
                  :items="categories"
                  v-model="product.categoryId"
                  label="Selecionae uma Categoria*"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" class="mt-n8">
                <span>Tempo Preparo(minutos)</span>
                <v-text-field
                  solo
                  type="number"
                  v-model="product.minutesDelivery"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="mt-n8">
                <span>Preço Inicial R$</span>
                <v-text-field
                  v-money="money"
                  solo
                  v-model.lazy="product.price"
                  label="Valor R$"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" class="mt-n8">
                <span>Descrição Completa</span>
                <v-textarea
                  solo
                  v-model="product.description"
                  rows="6"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="mt-n8">
                <v-btn text class="black--text" @click.native="openFileDialog">
                  {{ fileName ? fileName : "Imagem" }}
                  <v-icon right dark>mdi-camera</v-icon>
                </v-btn>
                <input
                  ref="fileRef"
                  type="file"
                  id="file-upload"
                  style="display: none"
                  @change="handleFileUpload"
                />
                <v-img
                  height="160"
                  width="160"
                  :src="urlPreview"
                  @click.native="openFileDialog"
                ></v-img>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearItem()">
                Fechar
              </v-btn>
              <v-btn
                :loading="loading"
                color="success"
                @click="registerOrUpdate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="registerDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="products"
          :search="search"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span>R$ {{ formatPrice(item.price) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              text
              small
              @click="
                registerDialog = true;
                product = item;
                productId = item.id;
                urlPreview = item.imageUrl
                  ? item.imageUrl
                  : 'https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png';
              "
            >
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
export default Vue.extend({
  name: "ProductsList",
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    deleteDialog: false,
    registerDialog: false,
    fullScreen: false,
    products: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview:
      "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      name: "",
      description: "",
      stock: "",
      minutesDelivery: 45,
      categoryId: "",
      price: "",
      imageUrl: "",
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Estoque Total",
        value: "stock",
        filtering: true,
      },
      {
        text: "Tempo Preparo(minutos)",
        value: "minutesDelivery",
        filtering: true,
      },
      {
        text: "Preço Inicial R$",
        value: "price",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    editItem(item) {
      this.product = item;
      this.urlPreview = item.images
        ? item.images[0]
        : "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png";
      this.productId = item.id;
      this.registerDialog = true;
    },
    deleteOpen(itemId) {
      this.productId = itemId;
      this.deleteDialog = true;
    },
    getCategories() {
      http.get("delivery/categories").then((res) => {
        this.categories = res.data;
      });
    },
    clearItem() {
      this.productId = null;
      this.product.name = "";
      this.product.variationType = "Tamanhos";
      this.product.categoryId = null;
      this.product.description = "";
      this.product.slug = "";
      this.product.minutesDelivery = 45;
      this.registerDialog = false;
      this.getItems();
    },
    async registerOrUpdate() {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("systemClientId", "pedbem");
        formData.append("companyId", "pedbem");
        formData.append("name", new Date().toString());
        formData.append("path", "pedbem/prodcuts");
        formData.append("type", "image-product");
        await http.post("marketing/files", formData).then((d) => {
          this.product.imageUrl = d.data.imageUrl;
        });
      }
      let price = this.product.price.toString().replace("R$ ", "");
      price = price.replace(".", "");
      price = price.replace(",", ".") * 1;
      this.product.price = price;
      if (this.productId) {
        await http
          .put(`delivery/products/${this.productId}`, this.product)
          .then(
            () => {
              this.snackbar.text = "Produto atualizado com sucesso";
              this.snackbar.color = "success";
              this.snackbar.opened = true;
              this.clearItem();
            },
            (err) => {
              const { response } = err;
              const message =
                typeof response?.data === "string"
                  ? response?.data
                  : response?.data?.title;
              this.snackbar.text = "Erro ao atualizar o produto: " + message;
              this.snackbar.color = "red";
              this.snackbar.opened = true;
            }
          );
      } else {
        await http.post("delivery/products", this.product).then(
          (res) => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.clearItem();
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
      }
    },
    getItems() {
      this.loading = true;
      http.get("delivery/products").then(
        (data) => {
          this.products = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`delivery/products/${this.productId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  directives: { money: VMoney },
  mounted() {
    this.getItems();
    this.getCategories();
  },
});
</script>
